import React from 'react';
import { FormattedMessage } from "react-intl";
import LoadingIndicator from './LoadingIndicator';

function Button({ title, className = '', onClick, isLoading = false, type = 'button', disabled = false, textClass = '', smallLoading = false }) {
	return (
		<button className={className} onClick={() => { if (!isLoading && !disabled) onClick() }} type={type} disabled={disabled}>
			{isLoading ?
				<LoadingIndicator small={smallLoading} /> :
				<span className={textClass}><FormattedMessage id={title} /></span>}
		</button>
	);
}

export default Button;
