export const defaultLocale = 'en';

export const locales = ['en', 'ar'];
export const rtlLocales = ['ar'];

// need to customize later
export const languageNames = {
	en: 'English',
	fr: 'français',
	pl: 'polski',
};
