import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PopoverBody, UncontrolledPopover } from "reactstrap";

function AssignmentFooter({
  sections,
  sectionsDetails,
  currentSectionID,
  setCurrentSectionID,
  onSubmit,
  submitAll,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (sections) {
      setCurrentIndex(sections.indexOf(currentSectionID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);
  const submit = () => {
    submitAll(currentSectionID);
  };
  return (
    <div className="assignmentFooter">
      <span className="questions">
        <FormattedMessage id="sections" />
      </span>
      {sections
        ? sections.map((question, index) => {
            return (
              <Fragment key={index}>
                <UncontrolledPopover
                  placement="top"
                  target={`section-${index + 1}`}
                  trigger="hover"
                  className="sections__title-popover"
                >
                  <PopoverBody className="sections__title-popover-body">
                    {sectionsDetails[question][0].details[0].title ||
                      index + 1 ||
                      ("0" + (index + 1)).slice(-2)}
                  </PopoverBody>
                </UncontrolledPopover>
                <div
                  key={index}
                  className={`question center semiBold cursor ${
                    index < currentIndex
                      ? "done"
                      : index === currentIndex
                      ? "current"
                      : ""
                  }`}
                  onClick={() => {
                    onSubmit(currentSectionID, question);
                    setCurrentSectionID(question);
                    setCurrentIndex(index);
                  }}
                  id={`section-${index + 1}`}
                >
                  {("0" + (index + 1)).slice(-2)}
                </div>
              </Fragment>
            );
          })
        : null}
      {sections && sections.length && currentIndex === sections.length - 1 ? (
        <button className="center submit" onClick={() => submit()}>
          <span className="buttonText">
            <FormattedMessage id="submitAllYourAnswers" />
          </span>
        </button>
      ) : null}
      <div className="autoMargin">
        {currentIndex > 0 ? (
          <button
            className="prev center"
            onClick={() => {
              onSubmit(currentSectionID, sections[currentIndex - 1]);
              setCurrentSectionID(sections[currentIndex - 1]);
              setCurrentIndex((prev) => prev - 1);
            }}
          >
            <span className="buttonText">
              <FormattedMessage id="previousSection" />
            </span>
          </button>
        ) : null}
        <div className="nextQuestionNav">
          {sections && currentIndex < sections.length - 1 ? (
            <button
              className="center nextQuestion"
              onClick={() => {
                onSubmit(currentSectionID, sections[currentIndex + 1]);
                setCurrentSectionID(sections[currentIndex + 1]);
                setCurrentIndex((prev) => prev + 1);
              }}
            >
              <span className="buttonText">
                <FormattedMessage id="nextSection" />
              </span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AssignmentFooter;
