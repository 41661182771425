import React from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'reactstrap'
import { TimeUpIcon } from '../AllSvg';

function TimeUp({ show, setShow, submit }) {
	return (
		<Modal
			isOpen={show}
			toggle={() => setShow(false)}
			className='modal-dialog-centered'
			modalClassName='thanksModal narrowModal'
			backdrop='static'
			keyboard={false}
		>
			<div className='body'>
				<TimeUpIcon />
				<span className='text40'>
					<FormattedMessage id='yourTimeIsUp' />
				</span>
				<div className='btns'>
					<button className='button center' onClick={submit}>
						<span className='buttonText'><FormattedMessage id="submitAllAnswers" /></span>
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default TimeUp;
