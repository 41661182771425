import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QuestionLangsButtons from "../QuestionLangsButtons";
import Priority from "../Priority";
import { Download, Upload } from "../AllSvg";
import { baseUrl } from "../../api";

function TextInput({
  languages,
  preferredLang,
  question,
  setQuestions,
  index,
  questions,
}) {
  const intl = useIntl();
  const [questionDetails, setQuestionDetails] = useState({});
  const [counter, setCounter] = useState(0);
  const [selectedLang, setSelectedLang] = useState(preferredLang);
  const [answer, setAnswer] = useState(question.answer);
  const [dir, setDir] = useState("");
  const [exceededLimit, setExceededLimit] = useState(false);

  const onAnswer = (value) => {
    const wordsCount = value ? value.split(" ").length : 0;
    setCounter(wordsCount);
    setAnswer(value);
    _onAnswer("answer", value);
    if (wordsCount > question.max_words) setExceededLimit(true);
    else setExceededLimit(false);
  };
  useEffect(() => {
    if (question.answer) {
      const wordsCount = question.answer
        ? question.answer.split(" ").length
        : 0;
      setCounter(wordsCount);
      if (wordsCount > question.max_words) setExceededLimit(true);
      else setExceededLimit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setQuestionDetails(
      question.details.filter(
        (i) => i.assessment_language_id === selectedLang
      )[0]
    );
    setDir(
      languages.filter((lang) => lang.language_id === selectedLang)[0]
        .language_direction
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang]);
  let temp;
  const _onAnswer = (type, value) => {
    temp = questions;
    temp[index][type] = value;
    setQuestions([...temp]);
  };

  return (
    <div className="questionWrapper">
      <QuestionLangsButtons
        languages={languages}
        selectedLang={selectedLang}
        setSelectedLang={setSelectedLang}
      />
      <div dir={dir} className="column">
        <span
          className="title aBeeZee"
          style={{ textAlign: dir === "rtl" ? "right" : "left" }}
        >
          {questionDetails.title}
        </span>
        <span style={{ textAlign: dir === "rtl" ? "right" : "left" }}>
          {questionDetails.description}
        </span>
      </div>
      {question.answer_attachment && (
        <a
          className="free-text-question__attachment-link"
          href={`${baseUrl}/storage/questions/${question.answer_attachment}`}
          target="_blank"
          rel="noreferrer"
          download
        >
          <span className="buttonText">
            <Download />
            <FormattedMessage id="question_attachment" />
          </span>
        </a>
      )}
      <span className="font12">
        <FormattedMessage id="yourAnswer" />
      </span>
      <textarea
        placeholder={intl.formatMessage({
          id: "typeHere",
        })}
        value={answer}
        onChange={(e) => onAnswer(e.target.value)}
        style={{ textAlign: dir === "rtl" ? "right" : "left" }}
      />
      <span className={`counter ${exceededLimit ? "warning" : ""}`}>
        {counter} / {question.max_words}
      </span>
      {question.require_priority === "1" ? (
        <Priority
          onSelect={(value) => _onAnswer("priority", value)}
          selected={question.priority}
        />
      ) : null}
      {question.answer_attachment && (
        <div className="free-text-question__attachment-upload-container">
          <label className="free-text-question__attachment-upload mb-0">
            <input
              type="file"
              hidden
              onChange={(e) => {
                if (e.target.value !== "")
                  _onAnswer("answer_attachment", e.target.files[0]);
              }}
              accept={"/*"}
            />
            <span className="buttonText">
              <Upload />
              <FormattedMessage id="answer_attachment" />
            </span>
          </label>
          {question.answer_attachment && (
            <span className="mx-3">
              {question?.uploaded_answer_attachment || ""}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default TextInput;
