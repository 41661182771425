import React, { useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'reactstrap'
import api from '../../api';
import Button from '../common/Button';

async function startAssess(token, data) {
	let response = await api.startAssessment(token, data);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data;
	}
	return false;
}

function SelectLanguage({ show, setShow, history, languages, assessment }) {
	const [selected, setSelected] = useState(null);
	const [Languages, setLanguages] = useState([]);
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem('token');
	let temp = [];
	useEffect(() => {
		if (show) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			temp = [];
			languages.map((lang) => {
				if (lang.language_id === assessment.assessment_language_id || lang.language_id === assessment.assessment_language_id_2nd)
					return temp.push(lang);
				else
					return null;
			})
			setLanguages(temp);
			setSelected(assessment.assessment_language_id);
			if (temp.length === 1) onContinue();
		}
	}, [show]);
	const onContinue = () => {
		setLoading(true);
		const data = {
			participant_id: assessment.participant_id,
			assessment_id: assessment.assessment_id,
			assessment_language_id: selected ?? assessment.assessment_language_id,
		};
		startAssess(token, data).then((res) => {
			setLoading(false);
			if (res) {
				history.push({
					pathname: '/guidelines',
					state: {
						assessment: { ...assessment, participant_assessment_id: res.participant_assessment_id },
						jobPosition: res.job_position,
						guidelines: res.guidelines,
						preferredLang: selected ?? assessment.assessment_language_id,
						languages: Languages.length ? Languages : temp,
					}
				});
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		})
	}
	return (
		<Modal
			isOpen={show}
			toggle={() => setShow(false)}
			className='modal-dialog-centered'
			modalClassName='thanksModal narrowModal'
		>
			<div className='body'>
				<span className='text40 marginBottom'>
					<FormattedMessage id='language' />
				</span>
				<div className='radioWrapper'>
					{Languages.map((lang, index) => {
						return <div key={index} className='row cursor' onClick={() => setSelected(lang.language_id)}>
							<div className={`circle center ${selected === lang.language_id ? 'selected' : ''}`}>
								{selected === lang.language_id && <div className='fill' />}
							</div>
							<span className='option'>{lang.language}</span>
						</div>
					})
					}
				</div>
				<div className='btns'>
					<Button className='button center wide'
						onClick={onContinue} title='continue'
						isLoading={loading} textClass='buttonText'
						smallLoading />
				</div>
			</div>
		</Modal>
	);
}

export default SelectLanguage;
