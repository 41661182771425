import React from 'react';
import { withRouter } from 'react-router-dom';
import GuidelinesImage from '../images/guidelines.png';
import Header from '../components/Header';
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import Image from '../components/common/Image';

function Guidelines({ history }) {
	const { innerHeight: height } = window;
	const location = useLocation();
	const { assessment, guidelines, preferredLang, languages } = location.state ?? {};
	const lang = localStorage.getItem('lang') || 'ar';
	return (
		<div className="landing-page guidelines">
			<Header assessment={assessment} />
			<div className='left-half'>
				<span className='guidelines-title aBeeZee'>
					<FormattedMessage id='guidelines' />
				</span>
				<div style={{ maxHeight: height - 300 }} id='scroll' className='scroll'>
					{guidelines ? guidelines.map((line, index) => {
						return <div className='guideline' key={index}>
							<div className='lineTitle'
								dangerouslySetInnerHTML={{ __html: lang === 'ar' ? line.title_ar : line.title_en }}
							/>
							<div
								dangerouslySetInnerHTML={{ __html: lang === 'ar' ? line.body_ar : line.body_en }}
							/>
						</div>
					}) : null}
				</div>
				{assessment ?
					<button className='getStartedBtn center autoWidth' onClick={() =>
						history.push({
							pathname: '/assessment',
							state: { assessment: assessment, preferredLang, languages }
						})
					}>
						<span><FormattedMessage id="acceptAndContinue" /></span>
					</button> : null}
			</div>
			<div className='relative'>
				<Image src={GuidelinesImage} style={{ height: height }} className='landing-img flip' alt='GuidelinesImage' />
			</div>
		</div >
	);
}

export default withRouter(Guidelines);
