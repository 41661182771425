import React from 'react';
import { baseUrl } from '../api';
import Image from './common/Image';

function Sponsors() {
	let logos = localStorage.getItem('clientLogos');
	const clientID = localStorage.getItem('clientID');
	if (logos) logos = logos.split(',');
	return (
		<div className='sponsors'>
			{logos ? logos.map((logo, index) => {
				return <Image key={index}
					src={`${baseUrl}/storage/clients/${clientID}/logo/${logo}`}
					alt={`client logo ${index + 1}`}
					clientLogo />
			}) : null}
		</div>
	);
}

export default Sponsors;
