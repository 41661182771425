/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { FormattedMessage } from "react-intl";
import { Table } from 'reactstrap'
import AssignmentCard from '../components/AssignmentCard';
import api from '../api';
import LoadingIndicator from '../components/common/LoadingIndicator';
import SelectLanguage from '../components/Modals/SelectLanguage';

async function getData(token) {
	let response = await api.assessments(token);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data;
	}
	return false;
}

function HomePage({ history }) {
	const { innerHeight: height } = window;
	const [loading, setLoading] = useState(true);
	const [assessments, setAssessments] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [showSelectLanguage, setShowSelectLanguage] = useState(false);
	const [selectedAssess, setSelectedAssess] = useState(null);
	const token = localStorage.getItem('token');
	let temp;
	useEffect(() => {
		temp = [
			{
				status: 'inProgress',
				count: 0
			},
			{
				status: 'notStarted',
				count: 0
			},
			{
				status: 'completed',
				count: 0
			},
		];
		getData(token).then((res) => {
			if (res) {
				setAssessments(res.assessments);
				setLanguages(res.assessment_languages);
				res.assessments.map((assessment) => {
					if (!assessment.participant_assessment_id)
						return temp[1].count += 1;
					else if (assessment.status === 'completed')
						return temp[2].count += 1;
					else
						return temp[0].count += 1;
				});
				setStatuses(temp);
			}
			setLoading(false);
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		})
	}, []);
	const hasNotStartedAssess = () => {
		return assessments.some((element) => !element.participant_assessment_id);
	}
	const hasCompletedAssess = () => {
		return assessments.some((element) => element.status === 'completed');
	}
	const hasInprogressAssess = () => {
		return assessments.some((element) => element.participant_assessment_id && element.status !== 'completed');
	}
	const showSelectLanguageHandler = (assessment) => {
		setSelectedAssess(assessment);
		setShowSelectLanguage(true);
	}
	let assessLang;
	const completeAssessment = (assessment) => {
		assessLang = [];
		languages.map((lang) => {
			if (lang.language_id === assessment.assessment_language_id || lang.language_id === assessment.assessment_language_id_2nd)
				return assessLang.push(lang);
			else 
				return null
		})
		history.push({
			pathname: '/assessment',
			state: { assessment: assessment, preferredLang: assessment.participant_assessment_language_id, languages: assessLang }
		});
	}
	return (
		<div className="home-page">
			<Sidebar history={history} />
			<div className='dashboard' style={{ minHeight: height }}>
				{loading ?
					<div className='loadingWrapper'>
						<LoadingIndicator />
					</div> :
					<>
						<span className='title'>
							<FormattedMessage id="myDashboard" />
						</span>
						<div className='row'>
							<div className='card'>
								<span className='title'><FormattedMessage id='summary' /></span>
								{statuses.map((status, index) => {
									return <div className='status' key={index}>
										<div className={`square center semiBold ${status.status}`}>
											{status.count}
										</div>
										<span><FormattedMessage id={status.status} /></span>
									</div>
								})}
							</div>
							<div className='card summaryTable'>
								<span className='title'><FormattedMessage id='summaryTable' /></span>
								<Table striped responsive>
									<thead>
										<tr>
											<th><FormattedMessage id='name' /></th>
											<th><FormattedMessage id='duration' /></th>
											<th><FormattedMessage id='status' /></th>
										</tr>
									</thead>
									<tbody>
										{assessments.map((row, index) => {
											return <tr key={index}>
												<td className='name'>
													{/* <span className='padding'>{lang === 'ar' ? row.instrument_ar : row.instrument_en}</span> */}
													<span className='padding'>{row.assessment_name}</span>
												</td>
												<td>{row.full_duration} <FormattedMessage id='minutes' /></td>
												<td className={row.status === 'completed' ? 'green' : row.status === 'started' ? 'purple' : 'orange'}>
													{(row.status_percentage * 100).toFixed(0)}% <FormattedMessage id='done' />
												</td>
											</tr>
										})}
									</tbody>
								</Table>
							</div>
						</div>
						{hasInprogressAssess() ?
							<>
								<span className='statusTitle'><FormattedMessage id='inProgress' /></span>
								<div className='assignmentsRow'>
									{assessments.map((assignment, index) => {
										if (assignment.participant_assessment_id && assignment.status !== 'completed')
											return <div key={index} className='assignmentsColumn'>
												<AssignmentCard data={assignment} completeAssessment={completeAssessment} />
											</div>
										else
											return null
									})}
								</div>
							</> : null}
						{hasNotStartedAssess() ?
							<>
								<span className='statusTitle'><FormattedMessage id='notStarted' /></span>
								<div className='assignmentsRow'>
									{assessments.map((assignment, index) => {
										if (!assignment.participant_assessment_id)
											return <div key={index} className='assignmentsColumn'>
												<AssignmentCard data={assignment} history={history} showSelectLanguage={showSelectLanguageHandler} />
											</div>
										else
											return null
									})}
								</div>
							</> : null}
						{hasCompletedAssess() ?
							<>
								<span className='statusTitle'><FormattedMessage id='completed' /></span>
								<div className='assignmentsRow'>
									{assessments.map((assignment, index) => {
										if (assignment.status === 'completed')
											return <div key={index} className='assignmentsColumn'>
												<AssignmentCard data={assignment} history={history} completeAssessment={completeAssessment} />
											</div>
										else
											return null
									})}
								</div>
							</> : null}
					</>}
			</div>
			<SelectLanguage show={showSelectLanguage} setShow={setShowSelectLanguage}
				languages={languages}
				assessment={selectedAssess}
				history={history} />
		</div>
	);
}

export default withRouter(HomePage);
