import React, { useState, useEffect } from 'react';
import QuestionLangsButtons from '../QuestionLangsButtons';
import Priority from '../Priority';

function NoAnswer({ languages, preferredLang, question, setQuestions, index, questions }) {
	const [selectedLang, setSelectedLang] = useState(preferredLang);
	const [questionDetails, setQuestionDetails] = useState({});
	const [dir, setDir] = useState('');
	useEffect(() => {
		setQuestionDetails(question.details.filter((i) => i.assessment_language_id === selectedLang)[0]);
		setDir(languages.filter((lang) => lang.language_id === selectedLang)[0].language_direction);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLang]);
	let temp;
	const onAnswer = (type, value) => {
		temp = questions;
		temp[index][type] = value;
		setQuestions([...temp]);
	}
	return (
		<div className='questionWrapper'>
			<QuestionLangsButtons languages={languages} selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<div dir={dir} className='column'>
				<span className='title aBeeZee' style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>{questionDetails.title}</span>
				<span style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
					{questionDetails.description}
				</span>
			</div>
			{question.require_priority === '1' ? <Priority onSelect={(value) => onAnswer('priority', value)} selected={question.priority} /> : null}
		</div >
	);
}

export default NoAnswer;
