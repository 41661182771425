import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import LandingImage from '../images/landing.png';
import LandingImageAr from '../images/landing-ar.png';
import Sponsors from '../components/Sponsors';
import { FormattedMessage } from "react-intl";
import SignInForm from '../components/SignInForm';
import PersonalInformationForm from '../components/PersonalInformationForm';
import VideosShow from '../components/VideosShow';
import LangsButtons from '../components/LangsButtons';
import Footer from '../components/Footer';
import Image from '../components/common/Image';

function HomePage({ history }) {
	const { innerHeight: height } = window;
	const [showTermsModal, setShowTerms] = useState(false);
	const storedStep = localStorage.getItem('signInStepVar') || 1;
	const [step, setStep] = useState(storedStep * 1);
	const termsDisplayed = localStorage.getItem('termsDisplayed');
	const lang = localStorage.getItem('lang') || 'ar';
	useEffect(() => {
		const loggedIn = localStorage.getItem('loggedIn');
		if (loggedIn)
			history.push(`/`);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onNext = (step) => {
		setStep(step);
		localStorage.setItem('signInStepVar', step);
	}
	const name = localStorage.getItem('clientName');
	return (
		<div className="landing-page">
			<LangsButtons />
			<div className='left-half'>
				<Sponsors />
				{step !== 2 ?
					<div>
						<div className='title'>
							<span>
								{step === 1 ?
									<FormattedMessage id="landingTitle" />
									: step === 3 ? <>
										<FormattedMessage id="hey" /> {name}
									</> : <FormattedMessage id="welcomeAboard" />
								}
							</span>
							{step === 4 ?
								<span>{name}</span> : null}
						</div>
						<div className='subtitle'>
							{step === 1 ? null :
								<FormattedMessage id={step === 3 ? "completeYourProfileToStart" : 'checkMaterials'} />}
						</div>
						{step === 1 &&
							<button className='getStartedBtn center capitalize' onClick={() => onNext(2)}>
								<span><FormattedMessage id="getStarted" /></span>
							</button>}
					</div> :
					step === 2 ?
						<SignInForm next={() => {
							if (!termsDisplayed) {
								setShowTerms(true);
								localStorage.setItem('termsDisplayed', 'yes');
							}
							onNext(3);
						}} /> :
						null}
				<Footer showTermsModal={showTermsModal} setShowTermsModal={setShowTerms} />
			</div>
			{step === 4 ?
				<VideosShow height={height} next={() => {
					localStorage.setItem('loggedIn', 'token');
					localStorage.removeItem('signInStepVar');
					history.push('/');
				}} /> :
				<div className='relative'>
					<Image src={lang === 'ar' ? LandingImageAr : LandingImage} style={{ height: height }} className='landing-img' alt='landing-img' />
					{step === 3 ? <div className='absolute'>
						<PersonalInformationForm next={() => onNext(4)} />
					</div> : null}
				</div>}
		</div >
	);
}

export default withRouter(HomePage);
