import React from 'react';
import ArIcon from '../images/ar.svg';
import { useLocale } from "../contexts/language/language.provider";

function LangsButtons() {
	const { locale, changeLanguage } = useLocale();
	return (
		<div className='langs'>
			<div className={`circle center ${locale === 'en' ? 'active' : ''}`}
				onClick={() => { if (locale !== 'en') changeLanguage('en'); }}>
				<span>EN</span>
			</div>
			<div className={`circle center ${locale === 'ar' ? 'active' : ''}`}
				onClick={() => { if (locale !== 'ar') changeLanguage('ar'); }}>
				<img src={ArIcon} alt='ar' />
			</div>
		</div>
	);
}

export default LangsButtons;
