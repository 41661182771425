import React from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'reactstrap'
import { Warning } from '../AllSvg';

function ConfirmSubmit({ show, setShow, onConfirm }) {
	const confirm = () => {
		onConfirm();
	}
	const cancel = () => {
		setShow(false);
	}
	return (
		<Modal
			isOpen={show}
			toggle={() => setShow(false)}
			className='modal-dialog-centered'
			modalClassName='thanksModal'
			backdrop='static'
			keyboard={false}
		>
			<div className='body'>
				<Warning />
				<span className='text26'>
					<FormattedMessage id='confirmSubmitText' />
				</span>
				<div className='btns'>
					<button onClick={cancel}>
						<span className='buttonText'><FormattedMessage id='cancel' /></span>
					</button>
					<button className='button center' onClick={confirm}>
						<span className='buttonText'><FormattedMessage id="confirm" /></span>
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default ConfirmSubmit;
