import React, { useState, useEffect } from 'react';
import QuestionLangsButtons from '../QuestionLangsButtons';
import { Menu } from '../AllSvg';
import Priority from '../Priority';

function ReorderQuestion({ languages, preferredLang, question, setQuestions, index, questions }) {
	const [selectedLang, setSelectedLang] = useState(preferredLang);
	const [answers, setAnswers] = useState(question.options);
	const [draggingElement, setDraggingElement] = useState(null);
	const [questionDetails, setQuestionDetails] = useState({});
	const [dir, setDir] = useState('');
	useEffect(() => {
		setQuestionDetails(question.details.filter((i) => i.assessment_language_id === selectedLang)[0]);
		setDir(languages.filter((lang) => lang.language_id === selectedLang)[0].language_direction);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLang]);
	let temp;
	const onDrop = (index) => {
		temp = answers.filter((answer) => (answer.id !== draggingElement.id));
		temp.splice(index, 0, draggingElement);
		setAnswers(temp);
		setDraggingElement(null);
	}
	let temp_;
	const onAnswer = (type, value) => {
		temp_ = questions;
		temp_[index][type] = value;
		setQuestions([...temp_]);
	}
	useEffect(() => {
		onAnswer('options', answers);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answers]);
	return (
		<div className='questionWrapper'>
			<QuestionLangsButtons languages={languages} selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<div dir={dir} className='column'>
				<span className='title aBeeZee' style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>{questionDetails.title}</span>
				<span className='questionText' style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
					{questionDetails.description}
				</span>
				{
					answers.map((answer, index) => {
						return <div className='dropContainer answerRow' key={index}
							onDragOver={(e) => {
								e.preventDefault();
							}}
							onDrop={(e) => {
								e.preventDefault();
								onDrop(index);
							}}>
							<div className={`dragElement ${draggingElement && answer.id === draggingElement.id ? 'dragging' : ''}`} draggable
								onDragStart={(event) => {
									event.dataTransfer.effectAllowed = "move";
									setDraggingElement(answer)
								}}
								onDragEnter={(event) => event.dataTransfer.dropEffect = "move"}
							>
								<Menu />
								{answer.details.map((op) => {
									if (op.assessment_language_id === selectedLang)
										return <span style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} key={op.id} >{op.option}</span>
									else
										return null
								})}
							</div>
						</div>
					})
				}
			</div>
			{question.require_priority === '1' ? <Priority onSelect={(value) => onAnswer('priority', value)} selected={question.priority} /> : null}
		</div >
	);
}

export default ReorderQuestion;
