import React, { useState } from 'react';
export const DataContext = React.createContext({});

export const DataProvider = ({ children }) => {
	const [reRender, setReRender] = useState(false);

	return (
		<DataContext.Provider value={{ setReRender, reRender }}>
			{children}
		</DataContext.Provider>
	);
};
