import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/Header';
import { FormattedMessage } from "react-intl";
import { HappyFace, NormalFace, SadFace } from '../components/AllSvg';
import ThanksModal from '../components/Modals/ThanksModal';
import { useLocation } from "react-router-dom";
import api from '../api';
import LoadingIndicator from '../components/common/LoadingIndicator';

async function sendFeedback(token, id, feedBack) {
	let response = await api.sendFeedback(token, id, feedBack);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data;
	}
	return false;
}

function ThankYou({ history }) {
	const location = useLocation();
	const { assessment, result } = location.state ?? {};
	const [feedBack, setFeedback] = useState('');
	const [showThanksModal, setShowThanksModal] = useState(false);
	const [sending, setSending] = useState(false);
	const token = localStorage.getItem('token');
	useEffect(() => {
		if (!result)
			history.push('/');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onSend = () => {
		setSending(true);
		sendFeedback(token, result.participant_assessment_id, feedBack).then((res) => {
			setSending(false);
			setShowThanksModal(true);
		}).catch((e) => {
			console.log(e);
			setSending(false);
		})
	}
	if (!result) return <div />;
	return (
		<div className="thankYou">
			<Header assessment={assessment} />
			<div className='thanksCard center'>
				<span className='aBeeZee text32'>
					<FormattedMessage id='thankYouText' />
				</span>
				<div className='cardsWrapper'>
					<div className='summaryCard green center'>
						<span className='text40'>{result.answers_count}/{result.questions_count}</span>
						<span className='text12'>
							<FormattedMessage id='questionsAnswered' />
						</span>
					</div>
					<div className='summaryCard purple center'>
						<span className='text40'>{result.attempts}</span>
						<span className='text12'>
							<FormattedMessage id='attempts' />
						</span>
					</div>
					<div className='summaryCard red center'>
						<span className='text40'>{result.minutes}</span>
						<span className='text12'>
							<FormattedMessage id='Minutes' />
						</span>
					</div>
				</div>
			</div>
			<div className='thanksCard feedback center'>
				<span className='aBeeZee text32'>
					<FormattedMessage id='ProvideUsWithFeedback' />
				</span>
				<div className='feedbackWrapper'>
					<div className={feedBack === 'could be better' ? 'selected' : ''} onClick={() => setFeedback('could be better')}>
						<SadFace />
						<FormattedMessage id='could be better' />
					</div>
					<div className={feedBack === 'good' ? 'selected' : ''} onClick={() => setFeedback('good')}>
						<NormalFace />
						<FormattedMessage id='good' />
					</div>
					<div className={feedBack === 'excellent' ? 'selected' : ''} onClick={() => setFeedback('excellent')}>
						<HappyFace />
						<FormattedMessage id='excellent' />
					</div>
				</div>
				<button onClick={() => {
					if (feedBack)
						onSend();
				}}>
					{sending ? <LoadingIndicator /> :
						<span className='buttonText text12'>
							<FormattedMessage id='sendFeedback' />
						</span>}
				</button>
			</div>
			<ThanksModal show={showThanksModal} setShow={setShowThanksModal} history={history} />
		</div >
	);
}

export default withRouter(ThankYou);
