import React from 'react';
import { FormattedMessage } from "react-intl";
import { baseUrl } from '../api';
import Image from './common/Image';

function AssignmentCard({ data, showSelectLanguage, completeAssessment }) {
	const lang = localStorage.getItem('lang') || 'ar';
	return (
		<div className='assignmentCard'>
			{!data.participant_assessment_id ?
				<div className='newLabel buttonText'>
					<FormattedMessage id='new' />
				</div>
				: null
			}
			<Image src={data.assessment_image ? `${baseUrl}/storage/assessments/${data.assessment_id}/${data.assessment_image}` : null} alt={lang === 'ar' ? data.instrument_ar : data.instrument_en} />
			{data.participant_assessment_id ?
				<div className='progressBar'>
					<div className={`progress ${data.status === 'completed' ? 'green' : !data.participant_assessment_id ? 'orange' : 'purple'}`} style={{ width: `${data.status_percentage * 100}%` }} />
				</div>
				: null}
			<div className='InfoWrapper'>
				<div className='infoRow'>
					<span className='name semiBold'>{lang === 'ar' ? data.instrument_ar : data.instrument_en}</span>
					<span className={`percentage aBeeZee ${data.status === 'completed' ? 'green' : !data.participant_assessment_id ? 'orange' : 'purple'}`}>
						{!data.participant_assessment_id ? <FormattedMessage id='notStarted' /> :
							data.status === 'completed' ? <>100% <FormattedMessage id='completed' /></>
								: <>{(data.status_percentage * 100).toFixed(0)}% <FormattedMessage id='done' /></>}
					</span>
				</div>
				<span >"{data.assessment_name}"</span>
				<span className='duration'>
					{data.full_duration} <FormattedMessage id='minutes' />
				</span>
				{data.status !== 'completed' ?
					<button className="continue" onClick={() => {
						if (!data.participant_assessment_id)
							showSelectLanguage(data);
						else completeAssessment(data);
					}}>
						<span className='semiBold'>	<FormattedMessage id={!data.participant_assessment_id ? 'getStarted' : 'continue'} /></span>
					</button> : null}
			</div>
		</div>
	);
}

export default AssignmentCard;
