import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { Close, File } from './AllSvg';
import AnnexCollapse from './AnnexCollapse';

function Annex({ height, close, data, languages }) {
	const [opened, setOpened] = useState(null);
	const toggle = (id) => {
		if (opened && opened === id)
			setOpened(null);
		else setOpened(id);
	}
	return (
		<div className='annex' style={{ height: height - 176 }}>
			<div className='annexHeader'>
				<File />
				<span className='aBeeZee'>
					<FormattedMessage id='annex' />
				</span>
				{data.length} <FormattedMessage id='documentsToRead' />
				<div className='closeIcon' onClick={close}>
					<Close />
				</div>
			</div>
			<div className='collapseWrapper' id='scroll' style={{ maxHeight: height - 256 }}>
				{data.map((annex, index) => {
					return <AnnexCollapse languages={languages} data={annex} onClick={toggle} isOpen={opened && opened === annex.display_order} key={index} />
				})}
			</div>
		</div>
	);
}

export default Annex;
