import React from 'react';
import getLang from '../helpers/getLang';

function QuestionLangsButtons({ languages, selectedLang, setSelectedLang }) {
	let isLtr = ("ar" !== getLang());
	return (
		<div className={`questionLangsButtonsNav ${isLtr ? 'questionLangsButtonsNavLtr' : 'questionLangsButtonsNavRtl'}`}>
			{languages.length > 1 ?
				<div className='questionLangsButtons'>
					{languages.map((lang, index) => {
						return <div key={index} style={{ display: 'flex' }}>
							<span className={selectedLang === lang.language_id ? 'active' : ''}
								onClick={() => { setSelectedLang(lang.language_id) }}>
								{lang.language}
							</span>
							{index < languages.length - 1 ? <div className='divider' /> : null}
						</div>
					})}
				</div> : null}
		</div>
	);
}

export default QuestionLangsButtons;
