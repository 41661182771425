/* eslint-disable import/no-anonymous-default-export */
import { toast } from 'react-toastify';
import getLang from '../helpers/getLang';
import clearStorage from '../helpers/clearStorage';
export const baseUrl = 'https://phi-portal.miniandmore.co';
const axios = require('axios');
let lang = getLang();

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        console.log(error);
        const originalRequest = error.config;
        if (!originalRequest._retry) {
            if (!error.response) return;
            if (error.response && error.response.status === 401) {
                clearStorage();
                window.location.reload();
            }
            else {
                let message = error.response.data.message;
                if (error.response.data.errors) {
                    message = '';
                    Object.keys(error.response.data.errors).map((key) => {
                        return message += error.response.data.errors[key] + (", ");
                    });
                }
                toast.error(message, {
                    position: 'top-right',
                    autoClose: 4000,
                });
            }
        }
    }
);
export default {
    login: (user) => {
        const formData = new FormData();
        formData.append('email', user.email);
        formData.append('password', user.password);
        return axios({
            url: `${baseUrl}/api/login`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
            },
            data: formData,
            withCredentials: true,
        });
    },
    changePassword: (token, data) => {
        const formData = new FormData();
        formData.append('current_password', data.currentPassword);
        formData.append('password', data.password);
        formData.append('password_confirmation', data.confirm);
        return axios({
            url: `${baseUrl}/api/change_password`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            data: formData,
            withCredentials: true,
        });
    },
    welcomeMaterials: (token, contractID) => {
        return axios({
            url: `${baseUrl}/api/contracts/${contractID}/welcome_materials`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    assessments: (token) => {
        return axios({
            url: `${baseUrl}/api/assessments`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    startAssessment: (token, data) => {
        const formData = new FormData();
        formData.append('participant_id', data.participant_id);
        formData.append('assessment_id', data.assessment_id);
        formData.append('assessment_language_id', data.assessment_language_id);
        return axios({
            url: `${baseUrl}/api/assessments/start`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            data: formData,
            withCredentials: true,
        });
    },
    assessmentDetails: (token, ids) => {
        return axios({
            url: `${baseUrl}/api/assessments/${ids.participant_assessment_id}/${ids.participant_id}/${ids.assessment_id}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    termsOfUse: () => {
        return axios({
            url: `${baseUrl}/api/static_content/terms_of_use`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
            },
            withCredentials: true,
        });
    },
    logout: (token) => {
        return axios({
            url: `${baseUrl}/api/logout`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    updateParticipantProfile: (token, data) => {
        const formData = new FormData();
        formData.append('_method', 'patch');
        formData.append('phone', data.phone);
        formData.append('age', data.age);
        if (data.nationality) formData.append('nationality', data.nationality);
        return axios({
            url: `${baseUrl}/api/update_participant_profile`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            data: formData,
            withCredentials: true,
        });
    },
    forgotPassword: (email) => {
        const formData = new FormData();
        formData.append('email', email);
        return axios({
            url: `${baseUrl}/api/forgot_password`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
            },
            data: formData,
            withCredentials: true,
        });
    },
    confirmResetCode: (data) => {
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('reset_code', data.code);
        return axios({
            url: `${baseUrl}/api/confirm_reset_code`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
            },
            data: formData,
            withCredentials: true,
        });
    },
    resetPassword: (data) => {
        const formData = new FormData();
        formData.append('password', data.password);
        formData.append('password_confirmation', data.confirm);
        formData.append('token', data.token);
        return axios({
            url: `${baseUrl}/api/reset_password`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
            },
            data: formData,
            withCredentials: true,
        });
    },
    sectionDetails: (token, ids) => {
        return axios({
            url: `${baseUrl}/api/assessments/${ids.participant_assessment_id}/${ids.participant_id}/${ids.assessment_id}/${ids.assessment_section_id}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    postSectionAnswers: (token, answers, ids, message) => {
        const formData = new FormData();
        formData.append('participant_id', ids.participant_id);
        formData.append('assessment_id', ids.assessment_id);
        formData.append('assessment_section_id', ids.assessment_section_id);
        let index = 0;
        // eslint-disable-next-line array-callback-return
        answers.map((answer) => {
            if (answer.answer_type === 'single_choice' && !answer.answer)
                return null;
            formData.append(`answers[${index}][question_id]`, answer.id);
            if (answer.priority) formData.append(`answers[${index}][priority]`, answer.priority);
            if (answer.answer_type === 'email') {
                formData.append(`answers[${index}][body]`, answer.body ?? '');
                formData.append(`answers[${index}][subject]`, answer.subject ?? '');
                if (answer.to) {
                    answer.to.map((recipient, index_) => {
                        return formData.append(`answers[${index}][to][${index_}]`, recipient.id);
                    })
                }
                if (answer.cc) {
                    answer.cc.map((recipient, index_) => {
                        return formData.append(`answers[${index}][cc][${index_}]`, recipient.id);
                    })
                }
                if (answer.bcc) {
                    answer.bcc.map((recipient, index_) => {
                        return formData.append(`answers[${index}][bcc][${index_}]`, recipient.id);
                    })
                }
            } else if (answer.answer_type === 'reorder') {
                answer.options.map((option, index_) => {
                    return formData.append(`answers[${index}][reorder][${index_}]`, option.id);
                })
            } else if (answer.answer_type === 'single_choice' && answer.answer) {
                formData.append(`answers[${index}][single_choice]`, answer.answer);
            } else if (answer.answer_type === 'free_text') {
                formData.append(`answers[${index}][free_text]`, answer.answer ?? '');
                formData.append(`answers[${index}][answer_attachment]`, answer.answer_attachment && typeof answer.answer_attachment === "object" ? answer.answer_attachment : '');
            }
            index++;
        });
        if (answers.length > index) {
            toast.error(message, {
                position: 'top-right',
                autoClose: 4000,
            });
        }
        if (index === 0) {
            return false;
        }
        return axios({
            url: `${baseUrl}/api/assessments/${ids.participant_assessment_id}/answers`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            data: formData,
            withCredentials: true,
        });
    },
    heartBeat: (token, ids) => {
        return axios({
            url: `${baseUrl}/api/assessments/${ids.participant_assessment_id}/${ids.participant_id}/${ids.assessment_id}/${ids.assessment_section_id}/beat`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    closeAssess: (token, participant_assessment_id) => {
        return axios({
            url: `${baseUrl}/api/assessments/${participant_assessment_id}/submit_all_answers`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    },
    sendFeedback: (token, participant_assessment_id, feedBack) => {
        const formData = new FormData();
        formData.append('feedback', feedBack);
        return axios({
            url: `${baseUrl}/api/assessments/${participant_assessment_id}/feedback`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Lang: lang,
                Authorization: `Bearer ${token}`,
            },
            data: formData,
            withCredentials: true,
        });
    },
};