import React from 'react';
import { Close, Annex } from './AllSvg';
import { Collapse } from 'reactstrap'
import { FormattedMessage } from "react-intl";

function AnnexCollapse({ data, onClick, isOpen, languages }) {
	const direction = languages.filter((i) => i.language_id === data.assessment_language_id)[0].language_direction;
	return (
		<div className='annexCollapse'>
			<div className='collapseHeader' onClick={() => onClick(data.display_order)}>
				<Annex />
				<span><FormattedMessage id='annex' /> {data.display_order}</span>
				<div className={`closeIcon ${!isOpen ? 'closed' : ''}`}>
					<Close />
				</div>
			</div>
			<Collapse isOpen={isOpen} >
				<div className='column' style={{ padding: 20 }} dir={direction}>
					<span className='aBeeZee notSelectable' style={{ marginBottom: 10, fontSize: 32, textAlign: direction === 'rtl' ? 'right' : 'left' }}>{data.title}</span>
					<div className='notSelectable'
						style={{ textAlign: direction === 'rtl' ? 'right' : 'left' }}
						dangerouslySetInnerHTML={{ __html: data.content }}
					/>
				</div>
			</Collapse>
		</div>
	);
}

export default AnnexCollapse;
