import React, { useState } from 'react';
import TermsModal from './Modals/TermsModal';
import { FormattedMessage } from "react-intl";

function Footer({ showTermsModal = false, setShowTermsModal }) {
	const currentYear = new Date().getFullYear();
	const [showTerms, setShowTerms] = useState(false);
	return (
		<>
			<div className='footer'>
				<span><FormattedMessage id="allRightsReserved" values={{ year: currentYear }} /></span>
				<span className='pointer underLine' onClick={() => { setShowTerms(true) }}>
					<FormattedMessage id="termsAndPrivacy" />
				</span>
			</div>
			<TermsModal
				showTerms={showTerms || showTermsModal}
				setShowTerms={(e) => {
					setShowTerms(e);
					if (setShowTermsModal)
						setShowTermsModal(false)
				}}
			/>
		</>
	);
}

export default Footer;
