import React from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'reactstrap'
import api from '../../api';
import LoadingIndicator from '../common/LoadingIndicator';
import { useState, useEffect } from 'react';

async function getData() {
	let response = await api.termsOfUse();
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data.terms_of_use;
	}
	return false;
}

function TermsModal({ showTerms, setShowTerms }) {
	const [loading, setLoading] = useState(true);
	const [content, setContent] = useState('');
	useEffect(() => {
		if (!content && showTerms)
			getData().then((res) => {
				if (res) {
					setContent(res);
				}
				setLoading(false);
			}).catch((e) => {
				console.log(e);
				setLoading(false);
			})
	}, [content, showTerms]);
	return (
		<Modal
			isOpen={showTerms}
			toggle={() => setShowTerms(false)}
			className='modal-dialog-centered'
			modalClassName='termsModal'
		>
			<div className='body'>
				<div className='scroll' id="scroll">
					<h1>
						<FormattedMessage id="termsAndPrivacy" />
					</h1>
					<div className='content'>
						{loading ? <LoadingIndicator /> : <div
							dangerouslySetInnerHTML={{ __html: content }}
						/>}
					</div>
				</div>
				<div className='termsModalFooter'>
					<button className='agree center' onClick={() => setShowTerms(false)}>
						<FormattedMessage id="iAgree" />
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default TermsModal;
