import React from 'react';
import { Lock, ClosedEye, Eye } from '../AllSvg';
import { useIntl } from "react-intl";

import { useState } from 'react';
const PasswordInput = ({ value, setValue, disabled, placeholder }) => {
	const intl = useIntl();
	const [showPassword, setShowPassword] = useState(false);
	return <div className='inputWrapper'>
		<Lock />
		<input placeholder={placeholder ? intl.formatMessage({
			id: placeholder,
		}) : null}
			value={value}
			required
			disabled={disabled}
			onChange={(e) => setValue(e.target.value)}
			type={showPassword ? 'text' : "password"}
			className='withIcon'
		/>
		<div className='eye'
			onClick={() => setShowPassword((prev) => !prev)}
		>
			{showPassword ? (
				<Eye />
			) : (
				<ClosedEye />
			)}
		</div>
	</div>;
};

export default PasswordInput;
