import React, { useEffect, useRef, useState } from 'react';
import LangsButtons from './LangsButtons';
import Sponsors from './Sponsors';
import { Clock } from './AllSvg';
import { FormattedMessage } from "react-intl";
import HarryUpModal from './Modals/HarryUp';
import TimeUpModal from './Modals/TimeUp';

function Header({ duration, assessment, submitAll }) {
	const [counter, setCounter] = React.useState(duration * 1);
	const userName = localStorage.getItem('clientName');
	const counterRef = useRef(counter);
	const [showHurryUp, setShowHurryUp] = useState(false);
	const [showTimeUp, setShowTimeUp] = useState(false);
	const lang = localStorage.getItem('lang') || 'ar';
	useEffect(() => {
		if (duration) {
			if (duration < 1) {
				setCounter(0);
				counterRef.current = 0;
				setShowTimeUp(true);
				return;
			}
			setCounter(duration * 1);
			counterRef.current = duration * 1;
			var interval = setInterval(function () {
				if (counterRef.current === 6)
					setShowHurryUp(true);
				if (counterRef.current === 1) {
					setShowTimeUp(true);
					clearInterval(interval);
				}
				setCounter((prev) => prev - 1);
				counterRef.current -= 1;
			}, 60000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [duration]);
	return (
		<div className='header'>
			<div className='subContainer'>
				<LangsButtons />
				<Sponsors />
			</div>
			<div className='avatar center aBeeZee'>{userName[0]}</div>
			<span className='userName aBeeZee'>{userName}</span>
			{assessment && <span className='name semiBold'>{lang === 'ar' ? assessment.instrument_ar : assessment.instrument_en}</span>}
			{assessment && <span className='type'>"{assessment.assessment_name}"</span>}
			{duration ? <div className='duration'>
				<Clock />
				<span className='marginLeft'><FormattedMessage id='timeLeft' />: </span>
				<span className='number minutes'>{counter}</span>
				<span><FormattedMessage id='minutes' /></span>
			</div> : null}
			<HarryUpModal show={showHurryUp} setShow={setShowHurryUp} />
			<TimeUpModal show={showTimeUp} setShow={setShowTimeUp} submit={() => {
				setShowTimeUp(false);
				submitAll();
			}} />
		</div>
	);
}

export default Header;
