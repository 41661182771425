import React from 'react';
import { useIntl } from "react-intl";
import { Editor } from 'react-draft-wysiwyg';

function TextEditor({ value, onChange, editorState, onEditorStateChange }) {
	const intl = useIntl();
	const uploadCallback = async (file) => {
		/*
		try {
			const json = await api.upload_image(
			  file,
			  this.props.id,
			  this.props.props.token
			);
			const res = await json.json();
			if (json.status >= 200 && json.status < 300) {
			  return new Promise((resolve, reject) => {
				resolve({ data: { link: res.url} });
			  });
			}
		  } catch (err) {
			console.log(err);
		  }*/
	}
	return (
		<Editor editorState={editorState} onEditorStateChange={onEditorStateChange}
			value={value}
			onChange={() => onChange()}
			toolbar={{
				options: ['blockType', 'inline', 'link', 'list', 'image', 'remove', 'history'],
				inline: {
					inDropdown: false,
					options: ['bold', 'italic'],
				},
				blockType: {
					inDropdown: true,
					options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
					className: undefined,
					component: undefined,
					dropdownClassName: undefined,
				},
				list: {
					inDropdown: false,
					className: undefined,
					component: undefined,
					dropdownClassName: undefined,
					options: ['unordered', 'ordered'],
				},
				image: {
					urlEnabled: true,
					uploadEnabled: true,
					alignmentEnabled: true,
					uploadCallback: uploadCallback,
					previewImage: true,
					inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
					alt: { present: false, mandatory: false },
					defaultSize: {
						height: 'auto',
						width: 'auto',
					},
				},
			}}
			placeholder={intl.formatMessage({
				id: "typeHere",
			})} />
	);
}

export default TextEditor;
