import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import api from '../api';
import Button from './common/Button';

async function send(token, data) {
	let response = await api.updateParticipantProfile(token, data);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data;
	}
	return false;
}


const ageRanges = [
	'Below 25 years old',
	'25-34 years old',
	'35-44 years old',
	'45-54 years old',
	'55-64 years old',
	'Above 64 years old'];
function PersonalInformationForm({ next }) {
	const storedAge = localStorage.getItem('clientAge');
	const [name, setName] = useState(localStorage.getItem('clientName'));
	const [email, setEmail] = useState(localStorage.getItem('clientEmail'));
	const [phone, setPhone] = useState(localStorage.getItem('clientPhone') ?? '');
	const [age, setAge] = useState(storedAge && storedAge !== '' ? storedAge : 'selectYourAge');
	const token = localStorage.getItem('token');
	const [loading, setLoading] = useState(false);
	const onNext = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setLoading(true);
		send(token, { phone, age: age === 'selectYourAge' ? '' : age }).then((res) => {
			setLoading(false);
			if (res) {
				next();
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		})
	}
	return (
		<div className="signInForm personalInfoForm">
			<div className="scroll" style={{ maxHeight: window.innerHeight - 160 }} id="scroll">
				<h1 className='purple'>
					<FormattedMessage id="completeYourProfile" />
				</h1>
				<span className='text32'>
					<FormattedMessage id="personalInformation" />
				</span>
				<form onSubmit={onNext}>
					<span className='label'>
						<FormattedMessage id="name" />
					</span>
					<div className='inputWrapper'>
						<input disabled required value={name} onChange={(e) => setName(e.target.value)} />
					</div>
					<span className='label'>
						<FormattedMessage id="emailAddress" />
					</span>
					<div className='inputWrapper'>
						<input disabled required type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
					</div>
					<span className='label'>
						<FormattedMessage id="phoneNumber" />
					</span>
					<div className='inputWrapper'>
						<input className='mobile' step="any" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+961 XX XXX XXX" />
					</div>
					{/*
					<span className='label'>
						<FormattedMessage id="position" />
					</span>
					<div className='inputWrapper'>
						<input placeholder={intl.formatMessage({
							id: "positionPlaceholder",
						})} disabled value={position}
							onChange={(e) => setPosition(e.target.value)} />
					</div>*/}
					<span className='label'>
						<FormattedMessage id="age" />
					</span>
					<UncontrolledButtonDropdown>
						<DropdownToggle caret>
							<FormattedMessage id={age} />
						</DropdownToggle>
						<DropdownMenu>
							{ageRanges.map((range, index) => {
								return <DropdownItem key={index} onClick={() => setAge(range)} tag='a'>
									<FormattedMessage id={range} />
								</DropdownItem>
							})}
						</DropdownMenu>
					</UncontrolledButtonDropdown>
					{/*
					<span className='label'>
						<FormattedMessage id="nationality" />
					</span>
					<div className='inputWrapper'>
						<input value={nationality} onChange={(e) => setNationality(e.target.value)} />
					</div>
					*/}
					<Button className='getStartedBtn center signIn' type='submit' title='next' isLoading={loading} onClick={() => { }} />
					<button className='skip center signIn semiBold' onClick={next}>
						<span><FormattedMessage id="skip" /></span>
					</button>
				</form>
			</div>
		</div>
	);
}

export default PersonalInformationForm;
