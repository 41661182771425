import React from 'react';
import { FormattedMessage } from "react-intl";
import LangsButtons from '../components/LangsButtons';
import Sponsors from '../components/Sponsors';
import Footer from '../components/Footer';
import api from '../api';
import LoadingIndicator from './common/LoadingIndicator';
import { useState } from 'react';
import clearStorage from '../helpers/clearStorage';

async function logout(token) {
	let response = await api.logout(token);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data;
	}
	return false;
}

function Sidebar({ history }) {
	const { innerHeight: height } = window;
	const userName = localStorage.getItem('clientName') || "";
	const userEmail = localStorage.getItem('clientEmail');
	const token = localStorage.getItem('token');
	const [loading, setLoading] = useState(false);
	const signOut = () => {
		setLoading(true);
		logout(token).then((res) => {
			setLoading(false);
			if (res) {
				clearStorage();
				history.push('/signin');
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		})
	}
	return (
		<>
			<div className="sidebar">
				<LangsButtons />
				<div className='relative' style={{ height: height - 50 }}>
					<div className='row'>
						<Sponsors />
					</div>
					<div className='userRow'>
						<div className='avatar center aBeeZee'>{userName[0]}</div>
						<div>
							<h3 className='semiBold aBeeZee'>{userName}</h3>
							<span>{userEmail}</span>
						</div>
					</div>
					{/*<div className='links'>
						{links.map((link, index) => {
							return <span key={index} className={`link ${history.location.pathname === link.link ? 'selected' : ''}`}>
								<FormattedMessage id={link.name} />
							</span>
						})}
					</div>*/}
					<div className='absolute'>
						<span className="link signOut" onClick={signOut}>
							<FormattedMessage id="signOut" />
						</span>
						<Footer />
					</div>
				</div>
			</div >
			{loading && <div className='overlay center'><LoadingIndicator /></div>}
		</>
	);
}

export default Sidebar;
