import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { User, LongArrowLeft } from './AllSvg';
import { CustomInput } from 'reactstrap'
import api from '../api';
import Button from '../components/common/Button';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import { DataContext } from '../contexts/data/data.provider';
import PasswordInput from './common/PasswordInput';

async function login(data) {
	let response = await api.login(data);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data;
	}
	return false;
}

function SignInForm({ next }) {
	const { setReRender } = useContext(DataContext);
	const forceToChangePass = localStorage.getItem('forceToChangePass');
	const [showChangePassword, setChangePassword] = useState(forceToChangePass ? true : false);
	const intl = useIntl();
	const [forgotPassword, setForgotPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const onSend = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setLoading(true);
		login({ email, password }).then((res) => {
			setLoading(false);
			if (res) {
				localStorage.setItem('token', res.access_token);
				localStorage.setItem('clientID', res.user.client_id);
				localStorage.setItem('clientName', res.user.full_name);
				localStorage.setItem('clientEmail', res.user.email);
				if (res.user.age) localStorage.setItem('clientAge', res.user.age);
				localStorage.setItem('clientPosition', res.user.job_position);
				localStorage.setItem('clientLogos', res.user.logos);
				if (res.user.nationality) localStorage.setItem('clientNationality', res.user.nationality);
				if (res.user.phone) localStorage.setItem('clientPhone', res.user.phone);
				localStorage.setItem('clientContractID', res.user.contract_id);
				setReRender((prev) => (!prev));
				if (res.user.enforce_change_password) {
					setChangePassword(true);
					localStorage.setItem('forceToChangePass', 'yes');
				} else {
					localStorage.removeItem('forceToChangePass');
					next();
				}
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		})
	}
	if (forgotPassword || showChangePassword)
		return (
			<div>
				<div className='backButton'
					onClick={() => {
						setForgotPassword(false);
						setChangePassword(false);
					}}>
					<LongArrowLeft />
					<FormattedMessage id="signIn" />
				</div>
				{showChangePassword ?
					<ChangePassword next={next} /> :
					<ForgotPassword next={() => setForgotPassword(false)} />
				}
			</div>);
	return (
		<div className="signInForm">
			<form onSubmit={onSend}>
				<h1 className='capitalize'>
					<FormattedMessage id="signIn" />
				</h1>
				<span className='label'>
					<FormattedMessage id="emailAddress" />
				</span>
				<div className='inputWrapper'>
					<input
						type="email"
						placeholder={'you@example.com'}
						value={email}
						required disabled={loading}
						onChange={(e) => setEmail(e.target.value)}
						className='withIcon' />
					<User />
				</div>
				<span className='label'>
					<FormattedMessage id="password" />
				</span>
				<PasswordInput value={password} setValue={setPassword} disabled={loading} placeholder='enterYourPassword' />
				<div className='link' onClick={() => setForgotPassword(true)}>
					<FormattedMessage id="resetPassword" />
				</div>
				<CustomInput type='checkbox' id='rememberMe' checked={rememberMe}
					label={intl.formatMessage({
						id: "rememberMe",
					})}
					onChange={e => setRememberMe(e.target.checked)} />
				<Button
					className='getStartedBtn center signIn normal'
					onClick={() => { }}
					title='signIn'
					type='submit'
					isLoading={loading} />
			</form>
		</div>
	);
}

export default SignInForm;
