import React from "react";
import Img from "react-image";
import placeholder from "../../images/placeholder.png";
import { ImagePlaceholder } from "../Skeletons";
import { useLocale } from '../../contexts/language/language.provider';

const Placeholder = ({ alt, style, className }) => <img src={placeholder} alt={alt} style={style} className={className} />;

export default function Image({ alt, src, style, className, clientLogo = false }) {
  const { isRtl } = useLocale();
  return (
    <Img
      src={src}
      unloader={clientLogo ? null : <Placeholder alt={alt} style={style} className={className} />}
      style={style}
      loader={<ImagePlaceholder rtl={isRtl} style={style} className={className} />}
      className={className}
    />
  );
}