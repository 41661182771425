import React, { useState, useEffect } from 'react';
import QuestionLangsButtons from '../QuestionLangsButtons';
import Priority from '../Priority';

function Radio({ languages, preferredLang, question, setQuestions, index, questions }) {
	const [questionDetails, setQuestionDetails] = useState({});
	const [selectedLang, setSelectedLang] = useState(preferredLang);
	const [dir, setDir] = useState('');
	const options = question.options;
	useEffect(() => {
		setQuestionDetails(question.details.filter((i) => i.assessment_language_id === selectedLang)[0]);
		setDir(languages.filter((lang) => lang.language_id === selectedLang)[0].language_direction);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLang]);
	let temp;
	const onAnswer = (type, value) => {
		temp = questions;
		temp[index][type] = value;
		setQuestions([...temp]);
	}
	return (
		<div className='questionWrapper'>
			<QuestionLangsButtons languages={languages} selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<div dir={dir} className='column'>
				<span style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} className='title aBeeZee'>{questionDetails.title}</span>
				<span style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} className='questionText'>
					{questionDetails.description}
				</span>
				<div className='radioWrapper'>
					{options.map((option, index) => {
						return <div key={index} className='answerRow cursor' onClick={() => onAnswer('answer', option.id)}>
							<div className={`circle center ${question.answer === option.id ? 'selected' : ''}`}>
								{question.answer === option.id && <div className='fill' />}
							</div>
							{option.details.map((op) => {
								if (op.assessment_language_id === selectedLang)
									return <span key={op.id} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} className='text'>{op.option}</span>
								else
									return null
							})}
						</div>
					})
					}
				</div>
			</div>
			{question.require_priority === '1' ? <Priority onSelect={(value) => onAnswer('priority', value)} selected={question.priority} /> : null}
		</div>
	);
}

export default Radio;
