import React from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'reactstrap'
import { Clock } from '../AllSvg';

function HarryUp({ show, setShow }) {
	return (
		<Modal
			isOpen={show}
			toggle={() => setShow(false)}
			className='modal-dialog-centered'
			modalClassName='thanksModal narrowModal hurryUpModal'
			backdrop='static'
			keyboard={false}
		>
			<div className='body'>
				<Clock />
				<span className='text40'>
					<FormattedMessage id='hurryUp' />
				</span>
				<span className='text'>
					<FormattedMessage id='hurryUpText' />
				</span>
				<div className='btns'>
					<button className='button center' onClick={() => setShow(false)}>
						<span className='buttonText'><FormattedMessage id="close" /></span>
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default HarryUp;
