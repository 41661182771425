import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import QuestionLangsButtons from '../QuestionLangsButtons';
import Editor from '../TextEditor';
import { Multiselect } from 'multiselect-react-dropdown';
import Priority from '../Priority';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

function EmailComposer({ languages, preferredLang, question, setQuestions, index, questions }) {
	const [selectedLang, setSelectedLang] = useState(preferredLang);
	const [counter, setCounter] = useState(0);
	const [subjectCounter, setSubjectCounter] = useState(0);
	const [answer, setAnswer] = useState('');
	const [exceededSubjectLimit, setExceededSubjectLimit] = useState(false);
	const [exceededBodyLimit, setExceededBodyLimit] = useState(false);
	const [subject, setSubject] = useState(question.subject || "");
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [questionDetails, setQuestionDetails] = useState({});
	const [dir, setDir] = useState('');
	let text;
	let wordsCount;
	let subjectWordsCount;
	useEffect(() => {
		let contentBlock;
		if (question.body) {
			contentBlock = htmlToDraft(question.body);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(
					contentBlock.contentBlocks
				);
				const editorState = EditorState.createWithContent(contentState);
				setEditorState(editorState);
				const text = editorState.getCurrentContent().getPlainText();
				const wordsCount = text ? text.split(' ').length : 0;
				setCounter(wordsCount);
				if (wordsCount > question.max_words)
					setExceededBodyLimit(true);
				else setExceededBodyLimit(false);
			}
		}
		if (question.subject) {
			const subjectWordsCount = question.subject ? question.subject.split(' ').length : 0;
			setSubjectCounter(subjectWordsCount);
			if (subjectWordsCount > question.max_subject_words)
				setExceededSubjectLimit(true);
			else setExceededSubjectLimit(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setQuestionDetails(question.details.filter((i) => i.assessment_language_id === selectedLang)[0]);
		setDir(languages.filter((lang) => lang.language_id === selectedLang)[0].language_direction);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLang]);
	const onAnswer = (value) => {
		text = editorState.getCurrentContent().getPlainText();
		wordsCount = text ? text.split(' ').length : 0;
		setCounter(wordsCount);
		setAnswer(text);
		_onAnswer('body', draftToHtml(convertToRaw(editorState.getCurrentContent())));
		if (wordsCount > question.max_words)
			setExceededBodyLimit(true);
		else setExceededBodyLimit(false);
	}
	const onAddSubject = (value) => {
		subjectWordsCount = value ? value.split(' ').length : 0;
		setSubject(value);
		setSubjectCounter(subjectWordsCount);
		_onAnswer('subject', value);
		if (subjectWordsCount > question.max_subject_words)
			setExceededSubjectLimit(true);
		else setExceededSubjectLimit(false);

	}
	let temp;
	const _onAnswer = (type, value) => {
		temp = questions;
		temp[index][type] = value;
		setQuestions([...temp]);
	}
	return (
		<div className='questionWrapper'>
			<QuestionLangsButtons languages={languages} selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<div dir={dir} className='column'>
				<span style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} className='title aBeeZee'>{questionDetails.title}</span>
				<span style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} className='marginBottom'>
					{questionDetails.description}
				</span>
			</div>
			<div className='emailTemplate'>
				<div className='title'>
					<FormattedMessage id='newEmail' />
				</div>
				<div className='relativeContainer'>
					<span className='label'>To:</span>
					<Multiselect
						options={question.recepients}
						avoidHighlightFirstOption
						placeholder=''
						displayValue="recepient" // Property name to display in the dropdown options
						onSelect={(value) => _onAnswer('to', value)}
						selectedValues={question.to}
						onRemove={(value) => _onAnswer('to', value)}
					/>
				</div>
				<div className='relativeContainer'>
					<span className='label'>Cc:</span>
					<Multiselect
						options={question.recepients}
						avoidHighlightFirstOption
						placeholder=''
						displayValue="recepient" // Property name to display in the dropdown options
						onSelect={(value) => _onAnswer('cc', value)}
						selectedValues={question.cc}
						onRemove={(value) => _onAnswer('cc', value)}
					/>
				</div>
				<div className='relativeContainer'>
					<span className='label'>Bcc:</span>
					<Multiselect
						options={question.recepients}
						avoidHighlightFirstOption
						placeholder=''
						displayValue="recepient" // Property name to display in the dropdown options
						onSelect={(value) => _onAnswer('bcc', value)}
						selectedValues={question.bcc}
						onRemove={(value) => _onAnswer('bcc', value)}
					/>
				</div>
				<div className='relativeContainer'>
					<input className='subjectInput' onChange={(e) => onAddSubject(e.target.value)} placeholder='Subject:' value={subject} />
					<span className={`subjectCounter label ${exceededSubjectLimit ? 'warning' : ''}`}>
						{subjectCounter} / {question.max_subject_words}
					</span>
				</div>
				<span className='font12 body'>
					<FormattedMessage id='body' />
				</span>
				<Editor editorState={editorState} onEditorStateChange={data => setEditorState(data)}
					value={answer}
					onChange={() => onAnswer()}
				/>
				<span className={`counter ${exceededBodyLimit ? 'warning' : ''}`}>
					{counter} / {question.max_words}
				</span>
			</div>
			{question.require_priority === '1' ? <Priority onSelect={(value) => _onAnswer('priority', value)} selected={question.priority} /> : null}
		</div >
	);
}

export default EmailComposer;
