
const clearStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('signInStepVar');
    localStorage.removeItem('phiToken');
    localStorage.removeItem('signInStep');
    localStorage.removeItem('clientNationality');
    localStorage.removeItem('clientAge');
    localStorage.removeItem('clientPhone');
    localStorage.removeItem('clientName');
    localStorage.removeItem('clientEmail');
    localStorage.removeItem('clientPosition');
    localStorage.removeItem('clientContractID');
    localStorage.removeItem('tourDisplayed');
};

export default clearStorage;
