import React, { useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import api, { baseUrl } from '../api';
import LoadingIndicator from './common/LoadingIndicator';
import { PlayIcon } from './AllSvg';
import Button from './common/Button';
import Image from './common/Image';

async function getData(token, clientID) {
	let response = await api.welcomeMaterials(token, clientID);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data;
	}
	return false;
}
function VideosShow({ next, height }) {
	const [loading, setLoading] = useState(true);
	const [showVideo, setShowVideo] = useState(false);
	const [data, setData] = useState([]);
	const [watched, setWatched] = useState([]);
	const [selectedVideo, setSelectedVideo] = useState(0);
	const token = localStorage.getItem('token');
	const lang = localStorage.getItem('lang') || 'ar';
	const clientContractID = localStorage.getItem('clientContractID');
	let temp = [];
	useEffect(() => {
		getData(token, clientContractID).then((res) => {
			if (res) {
				let temp_ = res.filter((i) => i.lang === lang);
				if (!temp_.length) next();
				setData(temp_);
				temp_.map((material, index) => {
					if (index === 0 & !material.youtube_link)
						return temp.push(true);
					else
						if (material.required_to_watch === '1')
							return temp.push(false);
						else return temp.push(true);
				});
				setWatched(temp);
			}
			setLoading(false);
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onNext = () => {
		if (selectedVideo === data.length - 1) {
			next();
			return;
		}
		if (!data[selectedVideo + 1].youtube_link) onWatch(selectedVideo + 1);
		if (watched[selectedVideo]) {
			setShowVideo(false);
			setSelectedVideo((prev) => (prev + 1));
		}
	}
	const onThumbClick = (index) => {
		if (index < selectedVideo) {
			setShowVideo(false);
			setSelectedVideo(index);
		} else if (index === selectedVideo + 1 && watched[selectedVideo]) onNext();
	}
	const onWatch = (index) => {
		temp = watched;
		temp[index] = true;
		setWatched([...temp]);
		setShowVideo(true);
	}
	const isSkipDisabled = () => {
		return watched.includes(false);
	}
	return (
		<div className="videosShow relative" style={{ height: height }} >
			{loading ? <LoadingIndicator /> :
				data.length ?
					<>
						<span className='title'>{data[selectedVideo].title}</span>
						{showVideo ? <iframe
							title="youtube video"
							src={
								data[selectedVideo].youtube_link.includes("watch")
									? "https://www.youtube.com/embed/" +
									data[selectedVideo].youtube_link.split("v=")[1].split("&")[0]
									: "https://www.youtube.com/embed/" +
									data[selectedVideo].youtube_link.split("youtu.be")[1].split("&")[0]
							}
							frameBorder="0"
						/> : <div className='coverImageWrapper'>
							<Image
								src={`${baseUrl}/storage/contracts/${clientContractID}/material/${data[selectedVideo].image_file}`}
								alt={data[selectedVideo].title} />
							{data[selectedVideo].youtube_link ?
								<div className='playIcon center' onClick={() => onWatch(selectedVideo)}>
									<PlayIcon />
								</div> : null}
						</div>}
						<div className='thumbs'>
							{data.map((video, index) => {
								return <div key={index} className={`cursor ${video.id === data[selectedVideo].id ? 'selected' : 'unselected'}`}
									onClick={() => {
										onThumbClick(index);
									}}>
									<Image src={`${baseUrl}/storage/contracts/${clientContractID}/material/thumbnail/${video.image_file}`} alt={video.title} />
								</div>
							})}
						</div>
						<div className='row'>
							{isSkipDisabled() ? null :
								<button className='skip center signIn semiBold' onClick={next}>
									<span><FormattedMessage id="skip" /></span>
								</button>}
							<Button className='getStartedBtn center signIn' onClick={onNext} title='next' disabled={!watched[selectedVideo]} />
						</div>
					</> : null}
		</div>
	);
}

export default VideosShow;
