import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import api from '../api';
import Button from './common/Button';
import PasswordInput from './common/PasswordInput';

async function changePassword(token, data) {
	let response = await api.changePassword(token, data);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data;
	}
	return false;
}

function ChangePassword({ next }) {
	const [loading, setLoading] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const token = localStorage.getItem('token');
	const onSend = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setLoading(true);
		changePassword(token, { currentPassword, password, confirm }).then((res) => {
			setLoading(false);
			if (res) {
				localStorage.removeItem('forceToChangePass');
				next();
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		});
	}
	return (
		<div className="signInForm noMargin">
			<form onSubmit={onSend}>
				<h1 className='capitalize'>
					<FormattedMessage id="changePassword" />
				</h1>
				<span className='label'>
					<FormattedMessage id="currentPassword" />
				</span>
				<PasswordInput value={currentPassword} setValue={setCurrentPassword} disabled={loading} />
				<span className='label'>
					<FormattedMessage id="newPassword" />
				</span>
				<PasswordInput value={password} setValue={setPassword} disabled={loading} />
				<span className='label'>
					<FormattedMessage id="confirmPassword" />
				</span>
				<PasswordInput value={confirm} setValue={setConfirm} disabled={loading} />
				<Button
					className='getStartedBtn center signIn normal'
					onClick={() => { }}
					title='confirm'
					type='submit'
					isLoading={loading} />
			</form>
		</div>
	);
}

export default ChangePassword;
