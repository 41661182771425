import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { User } from './AllSvg';
import api from '../api';
import Button from './common/Button';
import LoadingIndicator from '../components/common/LoadingIndicator';
import PasswordInput from './common/PasswordInput';

async function forgotPassword(email) {
	let response = await api.forgotPassword(email);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data;
	}
	return false;
}
async function confirmResetCode(data) {
	let response = await api.confirmResetCode(data);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data.data;
	}
	return false;
}
async function resetPassword(data) {
	let response = await api.resetPassword(data);
	if (response && response.status >= 200 && response.status < 300) {
		return response.data;
	}
	return false;
}

function ForgotPassword({ next }) {
	const [loading, setLoading] = useState(false);
	const [resending, setResending] = useState(false);
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState('');
	const [code, setCode] = useState('');
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const [token, setToken] = useState('');

	const sendEmail = () => {
		setResending(true);
		forgotPassword(email).then((res) => {
			setLoading(false);
			setResending(false);
			if (res) {
				setStep(2);
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
			setResending(false);
		});
	}
	const sendCode = () => {
		confirmResetCode({ email, code }).then((res) => {
			setLoading(false);
			if (res) {
				setToken(res.token);
				setStep(3);
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		});
	}
	const resetPass = () => {
		resetPassword({ password, confirm, token }).then((res) => {
			setLoading(false);
			if (res) {
				next();
			}
		}).catch((e) => {
			console.log(e);
			setLoading(false);
		});
	}
	const onSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setLoading(true);
		if (step === 1) sendEmail();
		else if (step === 2) sendCode();
		else resetPass();
	}
	return (
		<div className="signInForm">
			<form onSubmit={onSubmit}>
				<h1 className='capitalize'>
					<FormattedMessage id="resetPassword" />
				</h1>
				{step === 1 ?
					<>
						<span className='label'>
							<FormattedMessage id="emailAddress" />
						</span>
						<div className='inputWrapper'>
							<input
								type="email"
								placeholder={'you@example.com'}
								value={email}
								required disabled={loading}
								onChange={(e) => setEmail(e.target.value)}
								className='withIcon' />
							<User />
						</div>
					</> : step === 2 ?
						<>
							<span className='subtitleText'>
								<FormattedMessage id="enterVerificationCode" /> {email}
							</span>
							<span className='label'>
								<FormattedMessage id="code" />
							</span>
							<input
								type="number"
								value={code}
								required disabled={loading}
								onChange={(e) => setCode(e.target.value)} />
						</> : <>
							<span className='label'>
								<FormattedMessage id="newPassword" />
							</span>
							<PasswordInput value={password} setValue={setPassword} disabled={loading} />
							<span className='label'>
								<FormattedMessage id="confirmPassword" />
							</span>
							<PasswordInput value={confirm} setValue={setConfirm} disabled={loading} />
						</>}
				<Button
					className='getStartedBtn center signIn normal'
					onClick={() => { }}
					title='next'
					type='submit'
					isLoading={loading} />
				{step === 2 ? <div onClick={sendEmail}
					className='resendLink'>
					{resending ?
						<LoadingIndicator small /> :
						<FormattedMessage id="resend" />}
				</div> : null}
			</form>
		</div>
	);
}

export default ForgotPassword;
