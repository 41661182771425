import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Landing from './pages/Landing';
import Guidelines from './pages/Guidelines';
import Assignment from './pages/Assignment';
import ThankYou from './pages/ThankYou';

function Routes() {
	return (
		<Suspense fallback={null}>
			<Router>
				<ScrollToTop>
					<Switch>
						<ProtectedRoute path="/" exact component={Home} />
						<Route path="/signin" exact component={Landing} />
						<ProtectedRoute path="/guidelines" exact component={Guidelines} />
						<ProtectedRoute path="/assessment" exact component={Assignment} />
						<Route path="/thank-you" exact component={ThankYou} />
					</Switch>
				</ScrollToTop>
			</Router>
		</Suspense>
	);
}
export default Routes;
