import React from 'react';
import ContentLoader from 'react-content-loader';

export const ImagePlaceholder = ({ rtl, style, className }) => (
    <ContentLoader
        rtl={rtl}
        height="100%"
        width="100%"
        speed={2}
        backgroundColor='#f3f3f3'
        foregroundColor="#ecebeb"
        style={style}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
);