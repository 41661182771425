import React from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'reactstrap'
import { Check } from '../AllSvg';
import clearStorage from '../../helpers/clearStorage';

function ThanksModal({ show, setShow, history }) {
	const goToDashBoard = () => {
		history.push('/');
	}
	const logout = () => {
		clearStorage();
		history.push('/signin');
	}
	return (
		<Modal
			isOpen={show}
			toggle={() => setShow(false)}
			className='modal-dialog-centered'
			modalClassName='thanksModal'
			backdrop='static'
			keyboard={false}
		>
			<div className='body'>
				<Check />
				<span className='text40'>
					<FormattedMessage id='ThanksForFeedback' />
				</span>
				<div className='btns'>
					<button onClick={logout}>
						<span className='buttonText'><FormattedMessage id='logout' /></span>
					</button>
					<button className='button center' onClick={goToDashBoard}>
						<span className='buttonText'><FormattedMessage id="goToDashboard" /></span>
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default ThanksModal;
