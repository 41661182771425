import React from 'react';
import { FormattedMessage } from "react-intl";

const data = [
	'low',
	'medium',
	'high'
]
function Priority({ onSelect, selected }) {
	return (
		<div className='priorities'>
			<span className='title aBeeZee'>
				<FormattedMessage id="priority" />
			</span>
			<div className='row'>
				{
					data.map((item, index) => {
						return <div key={index} className={`priority cursor ${item === selected ? 'selected' : ''}`}
							onClick={() => onSelect(item)}>
							<span className='buttonText'><FormattedMessage id={item} /></span>
						</div>
					})
				}
			</div>
		</div>
	);
}

export default Priority;
