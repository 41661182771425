import React, { useEffect, useState, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";
import AssignmentFooter from "../components/AssignmentFooter";
import NoAnswer from "../components/Questions/NoAnswer";
import TextInput from "../components/Questions/TextInput";
import Radio from "../components/Questions/Radio";
import Reorder from "../components/Questions/Reorder";
import EmailComposer from "../components/Questions/EmailComposer";
import QuestionLangsButtons from "../components/QuestionLangsButtons";
import { File } from "../components/AllSvg";
import Annex from "../components/Annex";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Blur from "react-css-blur";
import api from "../api";
import LoadingIndicator from "../components/common/LoadingIndicator";
import ConfirmSubmitModal from "../components/Modals/ConfirmSubmit";
import TextAnnotation from "../components/TextAnnotation";

async function getData(token, ids) {
  let response = await api.assessmentDetails(token, ids);
  if (response && response.status >= 200 && response.status < 300) {
    return response.data.data;
  }
  return false;
}

async function getSection(token, ids) {
  let response = await api.sectionDetails(token, ids);
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return false;
}

async function postAnswers(token, data, ids, message) {
  let response = await api.postSectionAnswers(token, data, ids, message);
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return false;
}

async function heartBeat(token, ids) {
  let response = await api.heartBeat(token, ids);
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return false;
}
async function closeAssess(token, id) {
  let response = await api.closeAssess(token, id);
  if (response && response.status >= 200 && response.status < 300) {
    return response.data.data;
  }
  return false;
}

const skipBtnClass = "buttonText skipBtn center",
  nextBtnClass = "buttonText nextBtn center";

let instance = null;

function Assignment({ history }) {
  const location = useLocation();
  const { assessment, preferredLang, languages } = location.state ?? {};
  const intl = useIntl();
  const [questions, setQuestions] = useState([]);
  const sectionsContainerRef = useRef();
  const questionsContainerRef = useRef();
  const cancelTheTour = () => {
    instance.cancel();
    setDisplayTour(false);
  };
  const stepsCount = 5;
  const steps = [
    {
      id: "navAnnex",
      title: intl.formatMessage({
        id: "step1Title",
      }),
      text: intl.formatMessage({
        id: "step1Text",
      }),
      attachTo: { element: ".file", on: "right" },
      classes: "tourStep",
      buttons: [
        {
          text: intl.formatMessage(
            {
              id: "stepOf",
            },
            { nb: "1", steps: stepsCount }
          ),
          classes: "stepBtn",
          action: () => {},
        },
        {
          action: () => cancelTheTour(),
          classes: skipBtnClass,
          text: intl.formatMessage({
            id: "skip",
          }),
        },
        {
          text: intl.formatMessage({
            id: "next",
          }),
          classes: nextBtnClass,
          action: () => instance.next(),
        },
      ],
    },
    {
      id: "navLangs",
      title: intl.formatMessage({
        id: "step2Title",
      }),
      text: intl.formatMessage({
        id: "step2Text",
      }),
      attachTo: { element: ".langs", on: "top" }, //on: questions.length ? 'right' : 'left'
      classes: "tourStep",
      buttons: [
        {
          text: intl.formatMessage(
            {
              id: "stepOf",
            },
            { nb: "2", steps: stepsCount }
          ),
          classes: "stepBtn",
          action: () => {},
        },
        {
          action: () => cancelTheTour(),
          classes: skipBtnClass,
          text: intl.formatMessage({
            id: "skip",
          }),
        },
        {
          text: intl.formatMessage({
            id: "next",
          }),
          classes: nextBtnClass,
          action: () => instance.next(),
        },
      ],
    },
    {
      id: "navQuestions",
      title: intl.formatMessage({
        id: "step3Title",
      }),
      text: intl.formatMessage({
        id: "step3Text",
      }),
      attachTo: { element: ".question", on: "top" },
      classes: "tourStep",
      buttons: [
        {
          text: intl.formatMessage(
            {
              id: "stepOf",
            },
            { nb: "3", steps: stepsCount }
          ),
          classes: "stepBtn",
          action: () => {},
        },
        {
          action: () => cancelTheTour(),
          classes: skipBtnClass,
          text: intl.formatMessage({
            id: "skip",
          }),
        },
        {
          text: intl.formatMessage({
            id: "next",
          }),
          classes: nextBtnClass,
          action: () => instance.next(),
        },
      ],
    },
    {
      id: "navNextQuestion",
      title: intl.formatMessage({
        id: "step4Title",
      }),
      text: intl.formatMessage({
        id: "step4Text",
      }),
      attachTo: { element: ".nextQuestionNav", on: "top" },
      classes: "tourStep",
      buttons: [
        {
          text: intl.formatMessage(
            {
              id: "stepOf",
            },
            { nb: "4", steps: stepsCount }
          ),
          classes: "stepBtn",
          action: () => {},
        },
        {
          action: () => cancelTheTour(),
          classes: skipBtnClass,
          text: intl.formatMessage({
            id: "skip",
          }),
        },
        {
          text: intl.formatMessage({
            id: "next",
          }),
          classes: nextBtnClass,
          action: () => instance.next(),
        },
      ],
    },
    {
      id: "navMinutes",
      title: intl.formatMessage({
        id: "step5Title",
      }),
      text: intl.formatMessage({
        id: "step5Text",
      }),
      attachTo: { element: ".minutes", on: "bottom" },
      classes: "tourStep lastStep",
      buttons: [
        {
          text: intl.formatMessage(
            {
              id: "stepOf",
            },
            { nb: "5", steps: stepsCount }
          ),
          classes: "stepBtn",
          action: () => {},
        },
        {
          text: intl.formatMessage({
            id: "startTheTest",
          }),
          classes: nextBtnClass,
          action: () => cancelTheTour(),
        },
      ],
    },
  ];
  const { innerHeight: height } = window;
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [titleSize, setTitleSize] = useState(32);
  const [textSize, setTextSize] = useState(16);
  const [textFullscreen, setTextFullscreen] = useState(false);
  const [currentSectionID, setCurrentSectionID] = useState(null);
  const currentSectionIDRef = useRef(currentSectionID);
  const [given, setGiven] = useState({});
  const [givens, setGivens] = useState([]);
  const [selectedLang, setSelectedLang] = useState(preferredLang);
  const [showAnnex, setShowAnnex] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assessmentDetails, setAssessment] = useState({});
  const [displayTour, setDisplayTour] = useState(false);
  const [dir, setDir] = useState("");
  const token = localStorage.getItem("token");
  const [tourDisplayed, setTourDisplayed] = useState(false);
  let ids = {
    participant_assessment_id: assessment.participant_assessment_id,
    participant_id: assessment.participant_id,
    assessment_id: assessment.assessment_id,
    assessment_section_id: currentSectionIDRef.current,
  };
  const onHeartBeat = () => {
    ids = {
      participant_assessment_id: assessment.participant_assessment_id,
      participant_id: assessment.participant_id,
      assessment_id: assessment.assessment_id,
      assessment_section_id: currentSectionIDRef.current,
    };
    heartBeat(token, ids);
  };
  useEffect(() => {
    var interval = setInterval(function () {
      onHeartBeat();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const ids = {
      participant_assessment_id: assessment.participant_assessment_id,
      participant_id: assessment.participant_id,
      assessment_id: assessment.assessment_id,
    };
    getData(token, ids)
      .then((res) => {
        if (res) {
          setCurrentSectionID(res.sections[0]);
          currentSectionIDRef.current = res.sections[0];
          res.sections.forEach((sec) => {
            if (res.submitted_sections.includes(sec)) {
              setCurrentSectionID(sec);
              currentSectionIDRef.current = sec;
            }
          });
          setAssessment({ ...assessment, ...res });
          if (!res.sections.length) {
            setLoading(false);
          } else getSectionHandler(currentSectionIDRef.current);
        } else history.push("/");
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let temp;
  let reorder;
  const getSectionHandler = (sectionID) => {
    setLoading(true);
    const ids = {
      participant_assessment_id: assessment.participant_assessment_id,
      participant_id: assessment.participant_id,
      assessment_id: assessment.assessment_id,
      assessment_section_id: sectionID,
    };
    let answer;
    getSection(token, ids)
      .then((res) => {
        if (res) {
          setGivens(res.section.section_details);
          setGiven(
            res.section.section_details.filter(
              (i) => i.assessment_language_id === selectedLang
            )[0]
          );
          temp = res.section.questions;
          if (res.answers.length)
            // eslint-disable-next-line array-callback-return
            temp.map((question) => {
              answer = res.answers.filter(
                (i) => i.question_id === question.id
              )[0];
              if (answer) {
                question.priority = answer.priority;
                if (question.answer_type === "reorder") {
                  if (answer.reorder && answer.reorder.length) {
                    reorder = [];
                    answer.reorder.map((op) => {
                      return reorder.push(
                        question.options.filter((i) => i.id === op.option_id)[0]
                      );
                    });
                    question.options = reorder;
                  }
                } else if (question.answer_type === "single_choice") {
                  question.answer = answer.single_choice;
                } else if (question.answer_type === "free_text") {
                  question.answer = answer.details.text_body;
                  question.uploaded_answer_attachment =
                    answer.details.answer_attachment;
                } else if (question.answer_type === "email") {
                  question.body = answer.details.text_body;
                  question.subject = answer.details.text_subject;
                  question.to = [];
                  question.cc = [];
                  question.bcc = [];
                  answer.recepients.map((rec) => {
                    return question[rec.recepient_type].push(
                      question.recepients.filter(
                        (i) => i.id === rec.recepient_id
                      )[0]
                    );
                  });
                }
              }
            });
          setQuestions([...temp]);
          if (!res.section.questions.length) setTextFullscreen(true);
          else setTextFullscreen(false);
        }
        setLoading(false);
        if (res) {
          if (!tourDisplayed) {
            setTourDisplayed(true);
            setDisplayTour(true);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (selectedLang) {
      if (givens.length)
        setGiven(
          givens.filter((i) => i.assessment_language_id === selectedLang)[0]
        );
      setDir(
        languages.filter((lang) => lang.language_id === selectedLang)[0]
          .language_direction
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang]);
  const onSizeIncrease = () => {
    setTextSize((prev) => prev + 1);
    setTitleSize((prev) => prev + 1);
  };
  const onSizeDecrease = () => {
    setTextSize((prev) => prev - 1);
    setTitleSize((prev) => prev - 1);
  };
  const Content = () => {
    const tour = useContext(ShepherdTourContext);
    instance = tour;
    useEffect(() => {
      tour.start();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
  };
  const onSubmit = (sectionID, next, showSubmitAllModal, closeTheAssess) => {
    if (!questions.length && next) {
      getSectionHandler(next);
      return;
    }
    setLoading(true);
    const ids = {
      participant_assessment_id: assessment.participant_assessment_id,
      participant_id: assessment.participant_id,
      assessment_id: assessment.assessment_id,
      assessment_section_id: sectionID,
    };
    postAnswers(
      token,
      questions,
      ids,
      intl.formatMessage({
        id: "missingAnswersAlert",
      })
    )
      .then((res) => {
        if (next) getSectionHandler(next);
        else {
          setLoading(false);
          if (showSubmitAllModal) setShowConfirmSubmit(true);
          if (closeTheAssess) submitAll();
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const submitAll = () => {
    setShowConfirmSubmit(false);
    setLoading(true);
    closeAssess(token, assessment.participant_assessment_id)
      .then((res) => {
        setLoading(false);
        if (res) {
          history.push("/thank-you", { assessment: assessment, result: res });
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
    // clear local storage annotation
    assessmentDetails.sections.forEach((sectionId) => {
      localStorage.removeItem(
        `annotations_q_${assessment.participant_assessment_id}_${sectionId}`
      );
      localStorage.removeItem(
        `annotations_s_${assessment.participant_assessment_id}_${sectionId}`
      );
    });
  };

  return (
    <>
      {(displayTour || loading) && <div className="blockingLayer" />}
      {showAnnex && !loading && (
        <Annex
          languages={languages}
          height={height}
          close={() => setShowAnnex(false)}
          data={assessmentDetails.reading_materials}
        />
      )}
      <div className={`assignmentPage`}>
        <Header
          assessment={assessment}
          duration={
            Object.keys(assessmentDetails).length === 0
              ? null
              : (assessmentDetails.reading_minutes ?? 0) +
                (assessmentDetails.duration_minutes ?? 0) -
                (assessmentDetails.attempts_min ?? 0)
          }
          submitAll={() => onSubmit(currentSectionID, 0, false, true)}
        />
        {displayTour && (
          <ShepherdTour
            steps={steps}
            tourOptions={{
              useModalOverlay: true,
            }}
          >
            <Content />
          </ShepherdTour>
        )}
        {!loading && (
          <div className="file center" onClick={() => setShowAnnex(true)}>
            <File />
          </div>
        )}

        <Blur radius={displayTour ? "10px" : "0"} transition="400ms">
          <div
            className="blur"
            // {...(loading ? {} : { ref: questionsContainerRef })}
          >
            {loading ? (
              <div className="loadingWrapper">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <div
                  className="textContainer"
                  style={{
                    height: height - 176,
                    width: textFullscreen ? "100%" : "49%",
                  }}
                >
                  <QuestionLangsButtons
                    languages={languages}
                    selectedLang={selectedLang}
                    setSelectedLang={setSelectedLang}
                  />
                  <div className="textScale">
                    <div className="small center" onClick={onSizeDecrease}>
                      A
                    </div>
                    <div className="big center" onClick={onSizeIncrease}>
                      A
                    </div>
                  </div>
                  <div
                    className="scroll"
                    style={{ height: height - 220 }}
                    id="scroll"
                  >
                    <div className="column" dir={dir}>
                      <span
                        className="notSelectable title aBeeZee"
                        style={{
                          fontSize: titleSize,
                          textAlign: dir === "rtl" ? "right" : "left",
                        }}
                      >
                        {given.title}
                      </span>
                      <div
                        className="notSelectable given"
                        style={{
                          fontSize: textSize,
                          textAlign: dir === "rtl" ? "right" : "left",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: given.given_details,
                        }}
                        ref={sectionsContainerRef}
                      />
                      <TextAnnotation
                        textEl={sectionsContainerRef}
                        storageKey={`s_${assessment.participant_assessment_id}_${currentSectionID}`}
                      />
                    </div>
                  </div>
                </div>
                {!textFullscreen && (
                  <>
                    <div className={`questionsContainer`}>
                      <div
                        className="scroll"
                        style={{ height: height - 156 }}
                        id="scroll"
                      >
                        <div className="direction" ref={questionsContainerRef}>
                          {questions.map((question, index) => {
                            if (question.answer_type === "free_text") {
                              return (
                                <TextInput
                                  question={question}
                                  key={index}
                                  languages={languages}
                                  preferredLang={preferredLang}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questions={questions}
                                />
                              );
                            } else if (question.answer_type === "email")
                              return (
                                <EmailComposer
                                  question={question}
                                  key={index}
                                  languages={languages}
                                  preferredLang={preferredLang}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questions={questions}
                                />
                              );
                            else if (question.answer_type === "single_choice")
                              return (
                                <Radio
                                  question={question}
                                  key={index}
                                  languages={languages}
                                  preferredLang={preferredLang}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questions={questions}
                                />
                              );
                            else if (question.answer_type === "reorder")
                              return (
                                <Reorder
                                  question={question}
                                  key={index}
                                  languages={languages}
                                  preferredLang={preferredLang}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questions={questions}
                                />
                              );
                            else if (question.answer_type === "no_answer")
                              return (
                                <NoAnswer
                                  question={question}
                                  key={index}
                                  languages={languages}
                                  preferredLang={preferredLang}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questions={questions}
                                />
                              );
                            else
                              return (
                                <NoAnswer
                                  question={question}
                                  key={index}
                                  languages={languages}
                                  preferredLang={preferredLang}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questions={questions}
                                />
                              );
                          })}
                        </div>
                        <TextAnnotation
                          textEl={questionsContainerRef}
                          storageKey={`q_${assessment.participant_assessment_id}_${currentSectionID}`}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Blur>

        <AssignmentFooter
          sections={assessmentDetails.sections}
          sectionsDetails={assessmentDetails.section_details}
          currentSectionID={currentSectionID}
          setCurrentSectionID={(value) => {
            setCurrentSectionID(value);
            currentSectionIDRef.current = value;
          }}
          submitAll={(id) => onSubmit(id, 0, true)}
          onSubmit={onSubmit}
        />
      </div>
      <ConfirmSubmitModal
        show={showConfirmSubmit}
        setShow={setShowConfirmSubmit}
        onConfirm={submitAll}
      />
    </>
  );
}

export default withRouter(Assignment);
