import React from 'react';
import Routes from './Routes.js';
import './styles/App.scss';
import "react-toastify/dist/ReactToastify.css";
import localEn from './translations/en.json';
import localAr from './translations/ar.json';
import { LanguageProvider } from './contexts/language/language.provider';
import { DataProvider } from './contexts/data/data.provider';
import { ToastContainer } from "react-toastify";

const messages = {
  en: localEn,
  ar: localAr,
};
function App() {
  const { innerWidth: width } = window;
  const lang = localStorage.getItem('lang') ?? 'ar';
  React.useEffect(() => {
    document.title = lang === 'ar' ? 'مركز التقييم' : 'Digital Assessment';
    document.documentElement.setAttribute("lang", lang);
    document.documentElement.setAttribute("dir", lang === 'ar' ? 'rtl' : 'ltr');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (width < 800) return <div>Please open using wide screen</div>;
  if (!lang) return <div />;
  return (
    <LanguageProvider messages={messages} initLocale={lang}>
      <DataProvider>
        <Routes />
        <ToastContainer rtl={lang === "ar"} />
      </DataProvider>
    </LanguageProvider>
  );
}

export default App;
