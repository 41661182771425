import { useState, useEffect } from "react";
import { Recogito } from "@recogito/recogito-js";
import "@recogito/recogito-js/dist/recogito.min.css";

const TextAnnotation = ({ textEl, storageKey }) => {
  const [anno, setAnno] = useState();

  // Init Recogito when the component mounts
  const existingSectionAnnotations = JSON.parse(
    localStorage.getItem(`annotations_${storageKey}`)
  );

  useEffect(() => {
    let annotorious = null;
    let newSectionAnnotations = [];

    if (textEl.current) {
      // Init
      annotorious = new Recogito({
        allowEmpty: true,
        content: textEl.current,
        disableEditor: false,
        widgets: [],
        locale: localStorage.getItem("lang"),
      });

      // Attach event handlers
      annotorious.on("createAnnotation", (annotation) => {
        if (
          existingSectionAnnotations &&
          existingSectionAnnotations.length > 0
        ) {
          newSectionAnnotations = existingSectionAnnotations;
          newSectionAnnotations.push(annotation);
        } else {
          newSectionAnnotations.push(annotation);
        }
        return localStorage.setItem(
          `annotations_${storageKey}`,
          JSON.stringify(newSectionAnnotations)
        );
      });

      annotorious.on("deleteAnnotation", function (annotation) {
        const deletedAnnotationId = annotation.id;

        const deletedAnnotationIndex = existingSectionAnnotations.findIndex(
          (item) => item.id === deletedAnnotationId
        );

        existingSectionAnnotations.splice(deletedAnnotationIndex, 1);
        localStorage.setItem(
          `annotations_${storageKey}`,
          JSON.stringify(existingSectionAnnotations)
        );
      });
    }

    // Keep current Annotorious instance in state
    setAnno(annotorious);

    // Cleanup: destroy current instance
    return () => annotorious.destroy();
  }, []);

  useEffect(() => {
    if (anno && existingSectionAnnotations) {
      existingSectionAnnotations.map((annotation) =>
        anno.addAnnotation(annotation)
      );
    }
  }, [anno]);

  return <></>;
};

export default TextAnnotation;
